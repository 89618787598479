import React from 'react';

import { Background } from '../background/Background';
import { LandingSection } from '../layout/LandingSection';

const Video = () => (
  <Background color="bg-primary-500">
    <LandingSection>
      <div className="max-w-3xl mx-auto">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube-nocookie.com/embed/jUk2rCyrrZ4?rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </LandingSection>
  </Background>
);

export { Video };

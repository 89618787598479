import React from 'react';

import Link from 'next/link';

import { Button } from '../button/Button';
import { CTABanner } from '../cta/CTABanner';
import { LandingSection } from '../layout/LandingSection';

const Banner = () => (
  <LandingSection>
    <CTABanner
      title="Start generating social image and increase your visibility."
      subtitle="Get Started for Free."
      button={
        <Link href="/login">
          <a>
            <Button secondary>Get Started</Button>
          </a>
        </Link>
      }
    />
  </LandingSection>
);

export { Banner };

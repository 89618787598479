import React, { ReactNode } from 'react';

type ICTABannerProps = {
  title: string;
  subtitle: string;
  button: ReactNode;
};

const CTABanner = (props: ICTABannerProps) => (
  <div className="text-center flex flex-col p-4 sm:text-left sm:flex-row sm:items-center sm:justify-between sm:p-12 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 rounded-md">
    <div className="text-2xl font-semibold">
      <div className="text-gray-200">{props.title}</div>
      <div className="text-white">{props.subtitle}</div>
    </div>

    <div className="whitespace-no-wrap mt-3 sm:mt-0 sm:ml-2 flex-shrink-0">
      {props.button}
    </div>
  </div>
);

export { CTABanner };

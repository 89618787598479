import React from 'react';

import Link from 'next/link';

import { Background } from '../background/Background';
import { Button } from '../button/Button';
import { LandingSection } from '../layout/LandingSection';
import { NavbarMenuCenter } from '../navigation/NavbarMenuCenter';
import { Logo } from './Logo';

const Navbar = () => (
  <Background color="bg-primary-100">
    <LandingSection yPadding="py-6">
      <NavbarMenuCenter
        logo={<Logo xl />}
        rightMenu={
          <>
            <li>
              <Link href="/login">
                <a>
                  <Button>Sign in</Button>
                </a>
              </Link>
            </li>
          </>
        }
      />
    </LandingSection>
  </Background>
);

export { Navbar };

import React from 'react';

import Link from 'next/link';

import { Background } from '../background/Background';
import { CenteredFooter } from '../footer/CenteredFooter';
import { LandingSection } from '../layout/LandingSection';
import { Logo } from './Logo';

const Footer = () => (
  <Background color="bg-gray-100">
    <LandingSection>
      <CenteredFooter logo={<Logo />}>
        <li>
          <Link href="/privacy-policy">
            <a>Privacy Policy</a>
          </Link>
        </li>
        <li>
          <Link href="/terms-of-use">
            <a>Terms of Use</a>
          </Link>
        </li>
      </CenteredFooter>
    </LandingSection>
  </Background>
);

export { Footer };

import React from 'react';

import Link from 'next/link';

import { Background } from '../background/Background';
import { Button } from '../button/Button';
import { HeroOneButton } from '../hero/HeroOneButton';
import { LandingSection } from '../layout/LandingSection';

const Hero = () => (
  <Background color="bg-gray-200">
    <LandingSection yPadding="pt-20 pb-20">
      <HeroOneButton
        title={
          <>
            {'Increase Your '}
            <span className="text-primary-500">Social Media Reach</span>
          </>
        }
        description={
          <>
            {'The easiest way to create '}
            <span className="font-bold">eye-catching</span>
            {' and '}
            <span className="font-bold">colorful</span>
            {
              ' social share image automatically.\nGenerate images with only one line of code.'
            }
          </>
        }
        button={
          <Link href="/login">
            <a>
              <Button xl>Get Started for Free</Button>
            </a>
          </Link>
        }
        image={{
          src: '/assets/images/hero-image.png',
          alt: 'PostMage generated images for social networks',
        }}
      />
    </LandingSection>
  </Background>
);

export { Hero };

import React from 'react';

import { Background } from '../background/Background';
import { VerticalFeatureElement } from '../feature/VerticalFeatureElement';
import { VerticalFeatureElementColumn } from '../feature/VerticalFeatureElementColumn';
import { VerticalFeatureRow } from '../feature/VerticalFeatureRow';
import { LandingSection } from '../layout/LandingSection';

const VerticalFeatures = () => (
  <>
    <LandingSection>
      <VerticalFeatureRow
        title="Get noticed on social media"
        image={{
          src: '/assets/images/feature.svg',
          alt: 'Generated social media visuals',
        }}
        subtitle="Eye-catching and Colorful"
      >
        <p>
          Stand out on social media with colorful social share image. No design
          tool needed or tedious manual process, we automatically generate
          social images for you.
        </p>
        <VerticalFeatureElement
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M0 0h24v24H0z" stroke="none" />
              <path d="M12 3l8 4.5v9L12 21l-8-4.5v-9L12 3M12 12l8-4.5M12 12v9M12 12L4 7.5" />
            </svg>
          }
          title="Fast and easy"
        >
          Set up in 5 minutes by submitting your website information. We handle
          dynamically and automatically the rest for you.
        </VerticalFeatureElement>
        <VerticalFeatureElement
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M0 0h24v24H0z" stroke="none" />
              <path d="M3 21v-4a4 4 0 114 4H3M21 3A16 16 0 008.2 13.2M21 3a16 16 0 01-10.2 12.8M10.6 9a9 9 0 014.4 4.4" />
            </svg>
          }
          title="Highly customizable"
        >
          Choose your background color among 50+ templates and preview the
          generated images before adding into your website.
        </VerticalFeatureElement>
        <VerticalFeatureElement
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-bulb"
              viewBox="0 0 24 24"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M0 0h24v24H0z" stroke="none" />
              <path d="M3 12h1m8-9v1m8 8h1M5.6 5.6l.7.7m12.1-.7l-.7.7M9 16a5 5 0 116 0 3.5 3.5 0 00-1 3 2 2 0 01-4 0 3.5 3.5 0 00-1-3M9.7 17h4.6" />
            </svg>
          }
          title="Increase your revenue"
        >
          Share eye-catching visuals on social media. Optimize and boost your
          potential customer with ease.
        </VerticalFeatureElement>
      </VerticalFeatureRow>
    </LandingSection>

    <Background color="bg-gray-100">
      <LandingSection>
        <VerticalFeatureRow
          title="Set up in one line of code"
          image={{
            src: '/assets/images/code.png',
            alt: 'Generated social share image for Jamstack',
          }}
          subtitle="Easy to Integrate"
          reverse
        >
          <p>
            Generate social images for all major social networks and compatible
            with Jamstack websites and blogs:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
            <VerticalFeatureElementColumn
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M0 0h24v24H0z" stroke="none" />
                  <path d="M4 8V6a2 2 0 012-2h2M4 16v2a2 2 0 002 2h2M16 4h2a2 2 0 012 2v2M16 20h2a2 2 0 002-2v-2" />
                  <circle cx="12" cy="12" r="3" />
                </svg>
              }
              title="Perfect for social networks"
            >
              Increase your organic reach on social media like Facebook,
              Twitter, LinkedIn, Pinterest, etc.
            </VerticalFeatureElementColumn>
            <VerticalFeatureElementColumn
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M0 0h24v24H0z" stroke="none" />
                  <path d="M4 19h16M4 15l4-6 4 2 4-5 4 4" />
                </svg>
              }
              title="Jamstack compatible"
            >
              Integrate easily with all major Jamstack generators: Jekyll, Hugo,
              Next JS, Gatsby, 11ty, Nuxt
            </VerticalFeatureElementColumn>
          </div>
        </VerticalFeatureRow>
      </LandingSection>
    </Background>
  </>
);

export { VerticalFeatures };

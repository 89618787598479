import React from 'react';

import { Meta } from '../layout/Meta';
import { Banner } from '../template/Banner';
import { Footer } from '../template/Footer';
import { Hero } from '../template/Hero';
import { Navbar } from '../template/Navbar';
import { Pricing } from '../template/Pricing';
import { VerticalFeatures } from '../template/VerticalFeatures';
import { Video } from '../template/Video';
import { AppConfig } from '../utils/AppConfig';

const Index = () => (
  <div className="antialiased text-gray-600">
    <Meta title={AppConfig.title} description={AppConfig.description} />
    <Navbar />
    <Hero />
    <VerticalFeatures />
    <Video />
    <Pricing />
    <Banner />
    <Footer />
  </div>
);

export default Index;
